import {Expand} from '../../../../icons';
import {ArrowTop} from './index.styled';
import React from 'react';

type Props = {
	width?: number,
	height?: number,
	opened: boolean,
};

export function SelectableListTriggerIcon(props: Props) {
	const {
		width = 24,
		height = 24,
	} = props;
	if (props.opened) {
		return <ArrowTop width={width} height={height}/>;
	} else {
		return <Expand width={width} height={height}/>;
	}
}