import React, {ReactNode} from 'react';
import {CSS} from '@stitches/react';
import {styled} from '../../../../theme';
import {NavigationBarOrientation} from '../../types';
import {useNavigationBarContext} from '../../context';

type NavigationBarItemProps = {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;

	/** Item Content. */
	children?:
		| ReactNode
		| ((args: {
		expanded: boolean;
		setExpanded: (value: boolean) => void;
		orientation: NavigationBarOrientation;
	}) => React.JSX.Element);
	withHoverEffects?: boolean;
	onClick?: () => void;
	active?: boolean;
	column?: boolean
};

export default function NavigationBarItem({children, dataCy, onClick, active, withHoverEffects = true, ...props}: NavigationBarItemProps) {
	const {orientation, expanded, setExpanded} = useNavigationBarContext();

	return (
		<Item orientation={orientation} withHoverEffects={withHoverEffects} onClick={onClick} data-cy={dataCy ?? 'ui-kit-navigation-bar-item'} {...props}>
			{typeof children === 'function' ? children({expanded, setExpanded, orientation}) : children}
		</Item>
	);
}

const Item = styled('div', {
	display: 'flex',
	alignItems: 'center',
	textAlign: 'center',
	cursor: 'pointer',
	transition: '.2s ease',

	variants: {
		expanded: {
			true: {
				justifyContent: 'flex-start',
			},
		},

		withHoverEffects: {
			true: {
				'&:hover': {
					'& svg': {
						fill: '$primary',
						'& path': {
							fill: '$primary',
						},
					},
					'& span': {
						color: '$primary',
					},
				},
			},
		},

		orientation: {
			[NavigationBarOrientation.Horizontal]: {
				flexDirection: 'column',
			},

			[NavigationBarOrientation.Vertical]: {
				flexDirection: 'row',
			},
		},
	},

	compoundVariants: [
		{
			orientation: NavigationBarOrientation.Vertical,
			expanded: true,
			css: {
				width: '100%',
			},
		},
		{
			orientation: NavigationBarOrientation.Vertical,
			expanded: false,
			css: {
				display: 'flex',
				justifyContent: 'center',
			},
		},
	],
});
