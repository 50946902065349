import {Disposable} from '@esgillc/core/service';
import {filter, map, Observable, Subject, takeUntil} from 'rxjs';
import {SubscribeSubscription} from '../../api';
import {subscribe} from '../../graphql/subscriptions';
import {AmlifyManager} from '../../amplify/manager';


export class AppSyncService implements Disposable {
	private onDestroy$ = new Subject<void>();

	public channel<T = object>(identity: {userID: number, userType: string, messageType: string}): Observable<T> {
		const request = {
			query: subscribe,
			variables: {
				name: `${identity.userID}_${identity.userType}_${identity.messageType}`,
			},
		};

		return AmlifyManager.appSyncSubscribe<SubscribeSubscription>(request)
			.pipe(
				takeUntil(this.onDestroy$),
				map(d => {
					try {
						return JSON.parse(d.data.subscribe?.data);
					} catch (e) {
						console.error(e);
					}
				}),
				filter(Boolean),
			);
	}

	public dispose(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}
}