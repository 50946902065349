import {MutableRefObject, PropsWithChildren, useEffect, useMemo, useState} from 'react';
import {CSS} from '@stitches/react';
import {styled} from '../../../../theme';
import {Skeletonable} from '../../../../exports/core';
import {NavigationBarOrientation} from '../../types';
import {NavigationBarContext, NavigationBarContextValue} from '../../context';


type NavigationBarRootProps = PropsWithChildren & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;

	orientation: NavigationBarOrientation;

	dependElementRefs?: MutableRefObject<HTMLElement>[]

	closeOnOutsideClick?: boolean;
	isOpen?: boolean;
} & Skeletonable;

export default function NavigationBarRoot({
	orientation = NavigationBarOrientation.Vertical,
	children,
	dataCy,
	skeleton,
	closeOnOutsideClick,
	dependElementRefs,
	isOpen,
	...props
}: NavigationBarRootProps) {
	const [expanded, setExpanded] = useState<boolean>(false);

	useEffect(() => {
		if(isOpen !== undefined){
			setExpanded(isOpen);
		}
	}, [isOpen]);

	const context = useMemo<NavigationBarContextValue>(
		() => ({
			orientation: orientation,
			expanded,
			setExpanded: (v) => {
				setExpanded(v);
			},
		}),
		[orientation, expanded, setExpanded],
	);

	return (
		<NavigationBarContext.Provider value={context}>
			<Root data-cy={dataCy ?? 'ui-kit-navigation-bar-root'} orientation={orientation}
				  expanded={expanded} {...props}>
				{children}
			</Root>
		</NavigationBarContext.Provider>
	);
}

const Root = styled('nav', {
	display: 'flex',
	alignItems: 'center',
	backgroundColor: '$vivid',
	borderRight: '1px solid $vivid',
	boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.06), 0px 0px 8px 0px rgba(222, 227, 247, 0.40)',
	boxSizing: 'border-box',
	overflow: 'hidden',
	transition: 'height 0.3s, width 0.3s',
	variants: {
		expanded: {
			true: {},
		},
		orientation: {
			[NavigationBarOrientation.Vertical]: {
				flexDirection: 'column',
				width: '56px',
				height: '100%',
			},
			[NavigationBarOrientation.Horizontal]: {
				flexWrap: 'wrap',
				justifyContent: 'space-between',
				width: '100%',
				height: '64px',
				'& > section:nth-child(2)': {
					padding: '0 12px',
					width: '100%',
					height: '86px',
					order: 3,
					borderTop: '1px solid $border',
					'& > *': {
						margin: '0 6px',
					},
				},
			},
		},
	},
	compoundVariants: [
		{
			orientation: NavigationBarOrientation.Vertical,
			expanded: true,
			css: {
				width: '152px',
			},
		},
		{
			orientation: NavigationBarOrientation.Vertical,
			expanded: false,
			css: {
				width: '56px',
			},
		},
		{
			orientation: NavigationBarOrientation.Horizontal,
			expanded: true,
			css: {
				height: '200px',
			},
		},
	],
});
