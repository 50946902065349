import React, {MouseEvent, forwardRef, useCallback, useEffect, useMemo, ReactNode} from 'react';
import {ListItemProps} from '../../../list';
import {useSelectableListGroupContext} from '../selectable-list-group-root/context';
import {useSelectableListContext} from '../../context';
import {VariantProps} from '@stitches/react';
import {IconComponent} from '../../../../exports/core';
import {ListItem} from './index.styled';

export type SelectableListItemProps = Omit<ListItemProps, 'value' | 'disabled' | 'children'> & {
	/** A unique value for the item. */
	value: string;

	/** When true, prevents the user from interacting with the item. */
	disabled?: boolean;

	/** Prohibits or allows to select item. When true, item can be selected on click. */
	forceSelect?: boolean;

	/** Render an intem with a background color on hover or for selected item. Useful for a item with action buttons. */
	withActiveBackground?: boolean;

	/** Add icon before all content. If this value is not passed. Icon <Expand /> is displayed on hover. */
	IconBefore?: IconComponent;

	/** Item variants. By default used "default" type. */
	variant?: NonNullable<VariantProps<typeof ListItem>['itemVariant']>;

	children?: ListItemProps['children'] | ((isSelected: boolean) => ReactNode);
};

export const SelectableListItem = forwardRef<HTMLLIElement, SelectableListItemProps>(
	(
		{
			dataCy = 'ui-kit-selectable-list-item',
			value,
			onClick,
			disabled,
			forceSelect = true,
			withActiveBackground = true,
			variant = 'default',
			children,

			IconBefore,
			...props
		},
		forwaredRef,
	) => {
		const {isExpandableList} = useSelectableListContext();

		const {
			onItemActivate,
			onItemDeactivate,
			value: valueContexted,
			addValueToVocabulary,
			removeValueFromVocabulary,
		} = useSelectableListGroupContext();

		useEffect(() => {
			disabled ? removeValueFromVocabulary(value) : addValueToVocabulary(value);

			return () => removeValueFromVocabulary(value);
		}, [disabled]);

		const selected = useMemo(() => valueContexted.includes(value), [valueContexted, value]);

		const handleClick = useCallback(
			(event: MouseEvent<HTMLLIElement>) => {
				if (disabled || !forceSelect) {
					return;
				}

				event.stopPropagation();

				selected ? onItemDeactivate(value) : onItemActivate(value);
				onClick?.(event);
			},
			[onClick, selected, onItemActivate, onItemDeactivate, value, disabled, forceSelect],
		);

		return (
			<ListItem
				dataCy={dataCy}
				ref={forwaredRef}
				onClick={handleClick}
				selected={selected}
				isExpandableList={isExpandableList}
				disabled={Boolean(disabled)}
				withActiveBackground={withActiveBackground}
				itemVariant={variant}
				hasIconBefore={Boolean(IconBefore)}
				{...props}
			>
				{IconBefore && <IconBefore/>}
				{typeof children === 'function' ? children(selected) : children}
			</ListItem>
		);
	},
);
