import {Portal, PortalProps, TooltipContentProps as RadixTooltipContentProps} from '@radix-ui/react-tooltip';
import {forwardRef, useEffect} from 'react';
import {ThemeProvider} from '../../../theme';
import {CSS, VariantProps, CSSProperties} from '@stitches/react';
import {useTooltipContext} from '../../context';
import {isUndefined} from '../../../utils';
import {config, animated, useSpring} from 'react-spring';
import {Content} from './index.styled';
import {useGetTransformValue} from './use-get-transform-value';

export type TooltipContentProps = Omit<RadixTooltipContentProps, 'ref' | 'arrowPadding' | 'forceMount'> & {
	/** Data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;

	/** Properties which pass into Portal component.
	 * @see https://www.radix-ui.com/primitives/docs/components/tooltip#portal
	 */
	portalProps?: PortalProps;

	/** Color varinats of tooltip content. */
	variant?: VariantProps<typeof Content>['variant'];

	/** Color varinats of tooltip content. */
	size?: VariantProps<typeof Content>['size'];

	/**
	 * CSS property sets the z-order of a positioned element.
	 * @default 900
	 */
	zIndex?: CSSProperties['zIndex'];

	/**
	 * Property that allows to use line-break symbol "\n"
	 * @default false
	 */
	lineBreak?: boolean;
};

export const TooltipContent = forwardRef<HTMLDivElement, TooltipContentProps>(
	(
		{
			dataCy = 'ui-kit-tooltip-content',
			portalProps = {},
			sideOffset = 4,
			variant = 'default',
			side = 'bottom',
			children,
			size = 'medium',
			css = {},
			zIndex = 900,
			lineBreak = false,
			...props
		},
		forwardedRef,
	) => {
		const {delayDuration, setDelayDuration, isShownContent} = useTooltipContext();

		useEffect(() => {
			if (isUndefined(delayDuration)) {
				setDelayDuration(variant === 'secondary' ? 1000 : 400);
			}
		}, [variant]);

		const transform = useGetTransformValue({isShownContent, side});

		const animatedDivStyles = useSpring({
			opacity: isShownContent ? 1 : 0,
			transform,
			config: config.gentle,
		});

		return (
			<Portal {...portalProps}>
				<ThemeProvider>
					<Content
						data-cy={dataCy}
						ref={forwardedRef}
						sideOffset={sideOffset}
						lineBreak={lineBreak}
						variant={variant}
						side={side}
						size={size}
						css={{...css, zIndex}}
						forceMount
						asChild
						{...props}
					>
						<animated.div style={animatedDivStyles}>{children}</animated.div>
					</Content>
				</ThemeProvider>
			</Portal>
		);
	},
);
