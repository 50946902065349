import {useEffect} from 'react';
import {Loader} from '@esgillc/ui-kit/loader';
import {useService} from '@esgillc/core/service';
import {FallbackService} from '@ac/pages/home/service';
import {useNavigate} from 'react-router-dom';
import {routePaths} from '@ac/router/routes';

export function Fallback() {
	const service = useService(FallbackService);
	const navigate = useNavigate();

	useEffect(() => {
		service.isLoggedIn().subscribe(loggedIn => {
			if(loggedIn) {
				navigate(routePaths.studentAssignments);
			} else {
				navigate(routePaths.login);
			}
		});
	}, []);

	return <Loader show fullscreen/>;
}
