import React, {CSSProperties} from 'react';
import styles from './template.module.less';
import {isIpad, isVerticalOrientation, join} from '@esgillc/ui-kit/utils';
import {TemplateProps} from '../template';
import {getLineHeightByFontSize, getTextStyles, getUrl, isMultiple, TextSizeBreakpoints} from '../../utils';
import BaseOption from '../../option/option';
import AudioButton from '../../audio-button/audio-button';
import {ImagePosition} from '@packages/self-assess/models';

class WithTwoColumns extends React.Component<TemplateProps, {
	imageHeightCalculated: string;
}> {
	private columnRef = React.createRef<HTMLDivElement>();
	private textContainerRef = React.createRef<HTMLDivElement>();
	private audioTextRef = React.createRef<HTMLSpanElement>();
	private imgRef = React.createRef<HTMLImageElement>();

	constructor(props) {
		super(props);

		this.state = {
			imageHeightCalculated: '',
		};
	}

	public componentDidMount() {
		this.updateImageHeight();
		this.updateAudioBtnPosition();
	}

	public componentDidUpdate(prevProps) {
		/**
		 * update image height state only when question index has changed (on every question update)
 		 */
		if (this.props.questionIndex !== prevProps.questionIndex) {
			this.updateImageHeight();
		}

		this.updateAudioBtnPosition();
	}

	private selected(id: number): boolean {
		return this.props.selectedOptionID.includes(id);
	}

	private updateAudioBtnPosition() {
		const isTextOneLine = this.audioTextRef.current?.clientHeight < 60;
		const audioBtn = this.textContainerRef.current?.querySelector(`.${styles.topAudioBtn}`);

		if (audioBtn && isTextOneLine) {
			audioBtn.classList.add(styles.alignSelfCenter);
		} else {
			audioBtn?.classList.remove(styles.alignSelfCenter);
		}
	}

	render() {
		const noText = !this.props.question.text;
		const showImage = isIpad() ? (this.state.imageHeightCalculated || noText) : true;
		const imageAtBottom = this.props.question.imagePosition === ImagePosition.Bottom;
		const q = this.props.question;
		let className = styles.optionBox;

		if (q.options.length === 4) {
			className = join(className, styles.optionBox4Items);
		}

		return (
			<div className={styles.template}>
				<div className={join(styles.column, styles.left, imageAtBottom && styles.reverse)}>
					{showImage && this.renderPic()}
					{this.renderText()}
					{this.renderAudio()}
				</div>
				<div ref={this.columnRef} className={join(styles.column, styles.right)}>
					{q.options.map(item =>
						<BaseOption
							bucket={this.props.awsBucket}
							type={'Horizontal'}
							id={item.id}
							disabled={this.props.maxOptionsSelected && !this.props.selectedOptionID.includes(item.optionID)}
							multiselect={isMultiple(q)}
							key={item.optionID}
							text={item.text}
							className={className}
							clicked={() => this.props.onSelectOption(item.optionID)}
							selected={this.selected(item.optionID)}
							audioPath={item.audioUrl}
							disableAudio={this.props.audioPlaying}
							customize={{
								optionClassName: styles.customizeOption,
								actionClassName: styles.customizeAction,
							}}
						/>)}
				</div>
			</div>
		);
	}

	private renderText() {
		const isIpadDevice = isIpad();
		const text = this.props.question.text;
		if (!text) {
			return null;
		}
		const css: CSSProperties = {};
		const {textSizePx} = this.props.question;
		if (this.props.question.textSizePx) {
			css.fontSize = `${textSizePx}px`;
			css.lineHeight = `${getLineHeightByFontSize(textSizePx)}px`;
		}
		const needToCalcTextSize = text && (isIpadDevice || !textSizePx);
		if (needToCalcTextSize) {
			let breakPoint: TextSizeBreakpoints;
			if (isIpadDevice) {
				breakPoint = isVerticalOrientation() ? TextSizeBreakpoints.xs : TextSizeBreakpoints.xxs;
			} else {
				breakPoint = TextSizeBreakpoints.sm;
			}
			const {
				fontSize,
				lineHeight,
			} = getTextStyles(text, breakPoint);
			css.fontSize = `${fontSize}px`;
			css.lineHeight = `${lineHeight}px`;
		}
		return (
			<div ref={this.textContainerRef} className={join(styles.textContainer, styles.textContainerIpad)}>
				{this.renderAudioBtn()}
				<span ref={this.audioTextRef} style={css} className={styles.text} dangerouslySetInnerHTML={{__html: text}} />
			</div>
		);
	}

	private updateImageHeight() {
		const noText = !this.props.question.text;
		if (!this.columnRef.current || !this.textContainerRef.current || !isIpad() || noText) {
			return null;
		}

		const columnHeight = this.columnRef.current.clientHeight;
		const textHeight = this.textContainerRef.current.clientHeight;
		const imageHeight = columnHeight - textHeight - 40;

		this.setState({
			...this.state,
			imageHeightCalculated: `${imageHeight}px`,
		});
	}

	private renderPic() {
		if (!this.props.question.picUrl) {
			return null;
		}

		const style: CSSProperties = {};
		if (this.state.imageHeightCalculated) {
			style.maxHeight = this.state.imageHeightCalculated;
		}

		const imageAtBottom = this.props.question.imagePosition === ImagePosition.Bottom;
		const isIpadDevice = isIpad();
		const noBottomPadding = isIpadDevice && !imageAtBottom;
		const noTopPadding = isIpadDevice && imageAtBottom;
		const url = getUrl(this.props.awsBucket, this.props.question.picUrl);

		return (
			<div className={join(styles.imgContainer, noBottomPadding && styles.noBottomP, noTopPadding && styles.noTopP)}>
				<img ref={this.imgRef} src={url} style={style}/>
			</div>
		);
	}

	private renderAudioBtn() {
		const isIpadDevice = isIpad();
		if (!this.props.question.audioUrl) {
			return null;
		}

		const url = getUrl(this.props.awsBucket, this.props.question.audioUrl);
		return <AudioButton
			disabled={this.props.audioPlaying}
			className={join(styles.topAudioBtn, isIpadDevice && styles.audioBtnIpad)}
			id={this.props.question.id}
			audioUrl={url}
		/>;
	}

	private renderAudio() {
		if (!this.props.question.audioUrl || this.props.question.text) {
			return null;
		}

		const url = getUrl(this.props.awsBucket, this.props.question.audioUrl);

		return <AudioButton
			disabled={this.props.audioPlaying}
			className={styles.bigAudioBtn}
			id={this.props.question.id}
			audioUrl={url}
		/>;
	}
}

export default WithTwoColumns;
