import {isUndefined} from '../../../../../../utils';
import {Dispatch, PropsWithChildren, useCallback, useLayoutEffect, useState} from 'react';
import {SelectableListGroupRoot} from '../../../../components/selectable-list-group-root';
import {GroupMultipleProviderType as BaseGroupMultipleProviderType} from '../../../../components/selectable-list-group-root/components/group-multiple-provider';

export type GroupMultipleProviderType<T extends number = number> = PropsWithChildren<
	{
		/** The controlled value of the pressed item when type is "multiple". */
		value?: T[];

		/** The values of the items to show as pressed when initially rendered and type is "multiple". */
		defaultValue?: T[];

		/** Event handler called when the value changes. */
		onValueChange?: Dispatch<T[]>;
	} & Omit<BaseGroupMultipleProviderType, 'value' | 'defaultValue' | 'onValueChange'>
>;

export function GroupMultipleProvider<T extends number = number>({
	children,
	value,
	defaultValue,
	onValueChange,
	...props
}: GroupMultipleProviderType<T>) {
	const [selectedValue, setSelectedValue] = useState<string[]>(() => {
		const determinitedValue: T[] = value ?? defaultValue ?? [];

		return determinitedValue.map(String);
	});

	const handleSelectedValueChange = useCallback(
		(newValue: string[]) => {
			const adaptedNewValue = newValue.map(Number) as T[];

			setSelectedValue(newValue);
			onValueChange?.(adaptedNewValue);
		},
		[onValueChange],
	);

	useLayoutEffect(() => {
		if (isUndefined(value)) {
			return;
		}

		handleSelectedValueChange(value.map(String));
	}, [handleSelectedValueChange, setSelectedValue, value]);

	return (
		<SelectableListGroupRoot
			type='multiple'
			value={selectedValue}
			defaultValue={undefined}
			onValueChange={handleSelectedValueChange}
			{...props}
		>
			{children}
		</SelectableListGroupRoot>
	);
}
