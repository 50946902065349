import {isUndefined} from '../../../../../../utils';
import {Dispatch, PropsWithChildren, useCallback, useLayoutEffect, useState} from 'react';
import {isNull} from 'underscore';
import {SelectableListGroupRoot} from '../../../../components/selectable-list-group-root';
import {GroupSingleProviderType as BaseGroupSingleProviderType} from '../../../../components/selectable-list-group-root/components/group-single-provider';

export type GroupSingleProviderType<T extends number = number> = PropsWithChildren<
	{
		/** The controlled value of the pressed item when type is "single". */
		value?: T | null;

		/** The values of the items to show as pressed when initially rendered and type is "single". */
		defaultValue?: T;

		/** Event handler called when the value changes. */
		onValueChange?: Dispatch<T | null>;
	} & Omit<BaseGroupSingleProviderType, 'value' | 'defaultValue' | 'onValueChange'>
>;

export function GroupSingleProvider<T extends number = number>({
	children,
	value,
	defaultValue,
	onValueChange,
	...props
}: GroupSingleProviderType<T>) {
	const [selectedValue, setSelectedValue] = useState<T | null>(value ?? defaultValue ?? null);

	const handleSelectedValueChange = useCallback(
		(newValue: string) => {
			if (newValue === '') {
				setSelectedValue(null);
				onValueChange?.(null);

				return;
			}

			const adaptedNewValue = Number(newValue) as T;

			if (Number.isNaN(adaptedNewValue)) {
				throw new Error('newValue is invalid, check your items');
			}

			if (selectedValue === adaptedNewValue) {
				return;
			}

			setSelectedValue(adaptedNewValue);
			onValueChange?.(adaptedNewValue);
		},
		[onValueChange, selectedValue],
	);

	useLayoutEffect(() => {
		if (isUndefined(value)) {
			return;
		}

		if (isNull(value)) {
			handleSelectedValueChange('');
			return;
		}

		handleSelectedValueChange(String(value));
	}, [handleSelectedValueChange, setSelectedValue, value]);

	return (
		<SelectableListGroupRoot
			type='single'
			value={!isNull(selectedValue) ? String(selectedValue) : ''}
			defaultValue={undefined}
			onValueChange={handleSelectedValueChange}
			{...props}
		>
			{children}
		</SelectableListGroupRoot>
	);
}
