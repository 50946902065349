import {styled} from '../../../theme';
import {BaseButton} from '../base-button';

export const Button = styled(BaseButton, {
	color: '$base',
	padding: 7,
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: 6,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$border',
	backgroundColor: '$surface',
	transition: 'background-color .3s, color .3s, border-color .3s',

	'& svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},

	'&:hover:not(:disabled)': {
		color: '$secondary',
		borderColor: '$secondaryMuted',
		backgroundColor: '$secondaryBackground',
	},

	'&:disabled': {
		color: '$lowContrast',
		borderColor: '$border',
		backgroundColor: '$background',
	},

	variants: {
		isActive: {
			true: {
				color: '$primary',
				borderColor: '$primaryHighlight',
				backgroundColor: '$primaryBackground',
			},
		},
	},
});
