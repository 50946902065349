import {CSS} from '@stitches/react';
import React, {
	ComponentPropsWithoutRef,
	MouseEvent,
	ReactNode,
	forwardRef,
	useCallback,
	useMemo,
	useState,
} from 'react';
import {Skeletonable} from '../../../../types';
import {ExpandablePanelContext, ExpandablePanelContextValue} from '../../context';
import {styled} from '../../../../theme';
import {Box} from '../../../box';
import {useAutoControlledState} from '../../../../utils';

type ExpandablePanelRootProps = Omit<ComponentPropsWithoutRef<'div'>, 'children'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;

	/** The controlled open state of the expandable panel. */
	open?: boolean;

	/** Expandable panel content. */
	children?: ReactNode | ((args: {panelIsHovered: boolean; open: boolean}) => React.JSX.Element);
} & Skeletonable;

export const ExpandablePanelRoot = forwardRef<HTMLDivElement, ExpandablePanelRootProps>(
	({dataCy, skeleton, onMouseEnter, onMouseLeave, open: openControlled, children, ...props}, forwaredRef) => {
		const [open, setOpen] = useAutoControlledState({
			initialState: false,
			controlledState: openControlled,
		});

		const [panelIsHovered, setPanelIsHovered] = useState(false);
		const [placeholderWidth, setPlaceholderWidth] = useState(0);

		const value = useMemo<ExpandablePanelContextValue>(
			() => ({
				open,
				setOpen,
				panelIsHovered,
				setPanelIsHovered,
				placeholderWidth,
				setPlaceholderWidth,
			}),
			[open, panelIsHovered, placeholderWidth, setOpen],
		);

		const handleMouseEnter = useCallback(
			(event: MouseEvent<HTMLDivElement>) => {
				setPanelIsHovered(true);

				onMouseEnter?.(event);
			},
			[onMouseEnter],
		);

		const handleMouseLeave = useCallback(
			(event: MouseEvent<HTMLDivElement>) => {
				setPanelIsHovered(false);

				onMouseLeave?.(event);
			},
			[onMouseLeave],
		);

		return (
			<ExpandablePanelContext.Provider value={value}>
				<PanelBox
					data-cy={dataCy ?? 'expandable-panel-root'}
					ref={forwaredRef}
					data-expandable-panel-open={open}
					data-expandable-panel-hovered={panelIsHovered}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					{...props}
				>
					{typeof children === 'function'
						? children({
								panelIsHovered,
								open,
						  })
						: children}
				</PanelBox>
			</ExpandablePanelContext.Provider>
		);
	},
);

const PanelBox = styled(Box, {
	position: 'relative',
	height: '100%',
	borderRightStyle: 'solid',
	borderRightWidth: 1,
	borderRightColor: '$mild',
	boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.06), 0px 0px 8px 0px rgba(222, 227, 247, 0.40)',
	paddingTop: 6,
	paddingBottom: 6,
	transition: 'background-color .3s, background .3s, border-right-color .3s',

	'&[data-expandable-panel-open=false]': {
		backgroundColor: '$vivid',
		borderRightColor: '$primaryMuted',
		background: 'repeating-linear-gradient(-45deg, $vivid, $vivid 2px, $primaryBackground 0, $primaryBackground 6px)',

		'&[data-expandable-panel-hovered=true]': {
			borderRightColor: '$secondaryMuted',
			background: 'repeating-linear-gradient(-45deg, $vivid, $vivid 2px, $secondaryBackground 0, $secondaryBackground 6px)',
		},
	},

	'&[data-expandable-panel-open=true]': {
		backgroundColor: '$border',
	},
});
