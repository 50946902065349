import {Draggable, DraggableId} from 'react-beautiful-dnd';
import {DragDrop} from '../../../../icons';
import {ListItemProps} from '../list-item';
import useResizeObserver from '@react-hook/resize-observer';
import {useRef, useState} from 'react';
import {buttonDraggableHeight} from './constants';
import {ButtonDraggable, ItemContent, ItemDraggable} from './index.styled';

export type ListItemDraggableProps = ListItemProps & {
	/** Required item index for rearranging. */
	index: number;

	/** Required id used for rearranging. */
	draggableId: DraggableId;
};

export function ListItemDraggable({
	dataCy = 'ui-kit-list-item-draggable',
	index,
	draggableId,
	children,
	...props
}: ListItemDraggableProps) {
	const [contentHeight, setContentHeight] = useState(0);
	const contentRef = useRef<HTMLDivElement>(null);

	useResizeObserver(contentRef, (entry) => setContentHeight(entry.contentRect.height));

	return (
        /**
		 * Keep value draggableId and key the same.
		 * You cannot use the array index as value for the key attribute.
			key value should be a unique id and stable value (not change across re-render) that represents the element
		 */
        (<Draggable draggableId={draggableId} index={index} key={draggableId}>
            {(provided, snapshot) => (
				<ItemDraggable
					data-cy={dataCy}
					ref={provided.innerRef}
					isDragging={snapshot.isDragging}
					resetVerticalPadding={contentHeight <= buttonDraggableHeight}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					{...props}
				>
					<ItemContent ref={contentRef}>{children}</ItemContent>

					<ButtonDraggable dataCy={`${dataCy}-button`}>
						<DragDrop />
					</ButtonDraggable>
				</ItemDraggable>
			)}
        </Draggable>)
    );
}
