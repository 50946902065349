import {forwardRef} from 'react';
import {BaseButtonProps} from '../base-button';
import {VariantProps} from '@stitches/react';
import {ButtonStyled} from './index.styled';

type ButtonProps = Omit<BaseButtonProps, 'color'> &
	VariantProps<typeof ButtonStyled> & {
		/**
		 * Activate error mode styles, if the prop is true.
		 */
		error?: boolean;
	};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	({dataCy = 'ui-kit-button', color = 'primary', css = {}, error, ...props}, forwaredRef) => (
		<ButtonStyled
			data-cy={dataCy}
			color={color}
			css={css}
			data-state-error={error ? '' : undefined}
			ref={forwaredRef}
			{...props}
		/>
	),
);

