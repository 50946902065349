import {styled} from '../../../../theme';
import {List} from '../../../list';

export const ListItem = styled(List.Item, {
	cursor: 'pointer',
	transition: 'color .3s, background-color .3s, border-color .3s',
	outline: 'none',
	appearance: 'none',

	'& > svg': {
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},

	variants: {
		itemVariant: {
			default: {
				'&:first-child': {
					borderTopLeftRadius: 6,
					borderTopRightRadius: 6,
				},

				'&:last-child': {
					borderBottomColor: 'transparent',
					borderBottomLeftRadius: 6,
					borderBottomRightRadius: 6,
				},
			},

			outlined: {
				color: '$neutral40',
				borderWidth: 1,
				borderStyle: 'solid',
				borderColor: '$border',
				borderRadius: 6,

				'&:hover': {
					borderColor: '$secondaryMuted',
				},
			},
		},

		hasIconBefore: {
			true: {
				display: 'grid',
				gridTemplateColumns: 'auto 1fr',
				gap: 6,
				alignItems: 'center',

				'& > svg': {
					width: 24,
					height: 24,
					alignSelf: 'start',
				},
			},
		},

		isExpandableList: {
			true: {
				'&:first-child': {
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
				},
			},
		},

		selected: {
			true: {
				color: '$primary',
				borderBottomColor: '$primary72',
			},

			false: {
				'&:hover': {
					color: '$secondary',

					'&:not(:last-child)': {
						borderBottomColor: '$secondaryMuted',
					},
				},
			},
		},

		withActiveBackground: {
			true: {},
		},

		disabled: {
			true: {
				color: '$muted',
				backgroundColor: '$background',
				borderBottomColor: '$border',
				cursor: 'default',
				pointerEvents: 'none',
			},
		},
	},

	compoundVariants: [
		{
			withActiveBackground: true,
			selected: false,
			css: {
				'&:hover': {
					backgroundColor: '$secondaryBackground',
				},
			},
		},

		{
			withActiveBackground: true,
			selected: true,
			css: {
				backgroundColor: '$primaryBackground',
			},
		},

		{
			selected: true,
			itemVariant: 'default',
			css: {
				borderBottomColor: '$primary72',
			},
		},

		{
			selected: true,
			itemVariant: 'outlined',
			css: {
				borderColor: '$primary72',

				'&:hover': {
					borderColor: '$primary72',
				},
			},
		},
	],
});
