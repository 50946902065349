import {ErrorHandler} from '@esgillc/core/api';
import {routePaths} from '../../router/routes';

export class NotAuthorizedHandler extends ErrorHandler {
	public next(response: XMLHttpRequest): void {
		if (response?.status === 401) {
			window.location.replace(routePaths.login);
		}
	}
}
