import {Button} from '../../../../buttons';
import {ComponentProps, MouseEvent, forwardRef, useCallback, useLayoutEffect} from 'react';
import {useSelectableListContext} from '../../context';
import {StyledButton} from './index.styled';

type SelectableListStyledButtonProps = ComponentProps<typeof Button.Icon>;

const StyledButtonComponent = forwardRef<HTMLButtonElement, SelectableListStyledButtonProps>(
	(
		{
			dataCy = 'ui-kit-selectable-list-styled-button',
			onClick,
			withBackgroundHover = true,
			onMouseOver,
			onMouseOut,
			css = {},
			...props
		},
		forwaredRef,
	) => {
		const {triggerWithStyledButton, setTriggerWithStyledButton} = useSelectableListContext();

		useLayoutEffect(() => {
			setTriggerWithStyledButton({exist: true, hovered: false});

			return () => {
				setTriggerWithStyledButton({exist: false, hovered: false});
			};
		}, []);

		const handleClick = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				event.stopPropagation();

				onClick?.(event);
			},
			[onClick],
		);

		const handleMouseOver = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				setTriggerWithStyledButton({...triggerWithStyledButton, hovered: true});

				onMouseOver?.(event);
			},
			[onMouseOver, setTriggerWithStyledButton, triggerWithStyledButton],
		);

		const handleMouseOut = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				setTriggerWithStyledButton({...triggerWithStyledButton, hovered: false});

				onMouseOut?.(event);
			},
			[onMouseOut, setTriggerWithStyledButton, triggerWithStyledButton],
		);

		return (
			<StyledButton
				dataCy={dataCy}
				css={css}
				ref={forwaredRef as any}
				withBackgroundHover={withBackgroundHover}
				onClick={handleClick}
				onMouseOver={handleMouseOver}
				onMouseOut={handleMouseOut}
				{...props}
			/>
		);
	},
);

export const SelectableListStyledButton = Object.assign(StyledButtonComponent, {
	toString: () => `.${StyledButton.className}`,
});
