import {ComponentPropsWithoutRef, forwardRef, useRef} from 'react';
import {Box} from '../../../box';
import {CSS} from '@stitches/react';
import {useExpandablePanelContext} from '../../context';
import {styled} from '../../../../theme';
import {useComposedRefs} from '../../../../utils';
import useResizeObserver from '@react-hook/resize-observer';

type ExpandablePanelPlaceholderProps = ComponentPropsWithoutRef<'div'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
};

export const ExpandablePanelPlaceholder = forwardRef<HTMLDivElement, ExpandablePanelPlaceholderProps>(
	({dataCy, ...props}, forwaredRef) => {
		const {open, setPlaceholderWidth} = useExpandablePanelContext();

		const contentInnerRef = useRef<HTMLDivElement>(null);
		const contentRef = useComposedRefs(forwaredRef, contentInnerRef);

		useResizeObserver(contentInnerRef, (entry) => setPlaceholderWidth(entry.contentRect.width));

		if (open) {
			return null;
		}

		return <StyledBox dataCy={dataCy ?? 'expandable-panel-placeholder'} ref={contentRef} {...props} />;
	},
);

const StyledBox = styled(Box, {
	paddingTop: 18,
	paddingBottom: 18,
	paddingLeft: 8,
	paddingRight: 20,
});
