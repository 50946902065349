import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {StudentAssignmentTestingService} from '../../../../services/student-assignment-testing-service';
import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {useBehaviorSubject, useStreamEffect} from '@esgillc/ui-kit/utils';
import {EventBusManager} from '@esgillc/events';
import styles from './styles.module.less';
import {
	AudioButton,
	AudioEndPlayEvent,
	AudioStartPlayEvent,
	BackButton,
	NextButton,
	QuestionTemplate,
	TemplateType,
	getUrl,
} from '@packages/self-assess';
import {CloseButton} from '../../../close-button';
import {Subscription} from 'rxjs';

type TestModalProps = {
	closed: () => void;
	dataService: StudentAssignmentTestingService;
};

export function TestModal({closed, dataService}: TestModalProps) {
	const [questionStartTime, setQuestionStartTime] = useState(0);
	const [audioPlaying, setAudioPlaying] = useState(false);
	const [isSendingAnswer, setIsSendingAnswer] = useState(false);
	const [selectedOptionID, setSelectedOptionID] = useState<number[]>([]);

	const [nextQuestionSubscription, setNextQuestionSubscription] = useState<Subscription>();

	const modalRef = useModal();
	const modalClose = useCloseModal(modalRef, closed);

	const {current: eventBus} = useRef(new EventBusManager());

	useEffect(() => {
		eventBus.subscribe(AudioStartPlayEvent, () => setAudioPlaying(true));
		eventBus.subscribe(AudioEndPlayEvent, () => setAudioPlaying(false));

		return () => {
			eventBus.destroy();
			nextQuestionSubscription?.unsubscribe();
		};
	}, []);

	const currentQuestion = useBehaviorSubject(dataService.currentQuestion$);

	useStreamEffect(dataService.currentQuestion$, () => {
		setQuestionStartTime(new Date().getTime());
	});

	useStreamEffect(dataService.finishTest$, () => modalClose());

	const maxOptionsSelected = useMemo(() => {
		if (currentQuestion?.correctOptionsCount === 1) {
			return false;
		}

		return currentQuestion?.correctOptionsCount === selectedOptionID.length;
	}, [currentQuestion, selectedOptionID]);

	const backButtonDisabled = useMemo(() => {
		if (audioPlaying) {
			return true;
		}

		return dataService.isLastQuestion;
	}, [audioPlaying, dataService.isLastQuestion]);

	const nextButtonDisabled = useMemo(() => {
		if (audioPlaying || isSendingAnswer) {
			return true;
		}

		if (!currentQuestion) {
			return false;
		}

		if (currentQuestion.correctOptionsCount > 1) {
			if (currentQuestion.options.length > 3) {
				return selectedOptionID.length <= 1;
			} else {
				return currentQuestion.correctOptionsCount !== selectedOptionID.length;
			}
		}

		if (currentQuestion.templateType === TemplateType.Practice) {
			return false;
		}

		return selectedOptionID.length === 0;
	}, [audioPlaying, currentQuestion, selectedOptionID, isSendingAnswer]);

	const onSelectOption = useCallback(
		(id: number) => {
			if (currentQuestion?.correctOptionsCount === 1) {
				setSelectedOptionID([id]);

				return;
			}

			const options = [...selectedOptionID];

			if (options.includes(id)) {
				const filteredOptions = options.filter((optionId) => optionId !== id);

				setSelectedOptionID(filteredOptions);
			} else {
				setSelectedOptionID([...options, id]);
			}
		},
		[currentQuestion, selectedOptionID],
	);

	const backClicked = useCallback(() => {
		const optionID = dataService.previousQuestion();

		setSelectedOptionID(optionID);
	}, [dataService]);

	const nextClicked = useCallback(() => {
		setIsSendingAnswer(true);

		const endTime = new Date().getTime();
		const duration = endTime - questionStartTime;

		const nextQuestionSubscription = dataService.nextQuestion(selectedOptionID, duration).subscribe({
			next: setSelectedOptionID,
			complete: () => {
				setIsSendingAnswer(false);
			},
		});

		setNextQuestionSubscription(nextQuestionSubscription);
	}, [questionStartTime, dataService, selectedOptionID]);

	if (!currentQuestion) {
		return null;
	}

	return (
		<Modal containerClassName={styles.modalWrapper} className={styles.modal} modalManagerRef={modalRef}>
			<Modal.Header className={styles.testHeader}>
				<div className={styles.questionContainer}>
					<AudioButton
						disabled={audioPlaying}
						id={currentQuestion.id}
						audioUrl={getUrl(dataService.awsBucket, currentQuestion.audioDirectionUrl)}
					/>
					{currentQuestion.directionsText && (
						<span dangerouslySetInnerHTML={{__html: currentQuestion.directionsText}} />
					)}
				</div>
				<CloseButton closed={modalClose} />
			</Modal.Header>
			<Modal.Body className={styles.testBody}>
				<div className={styles.questionWrapper}>
					<QuestionTemplate
						awsBucket={dataService.awsBucket}
						question={currentQuestion}
						selectedOptionID={selectedOptionID}
						onSelectOption={onSelectOption}
						maxOptionsSelected={maxOptionsSelected}
						audioPlaying={audioPlaying}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer className={styles.testFooter}>
				<BackButton disabled={backButtonDisabled} clicked={backClicked} />
				<NextButton disabled={nextButtonDisabled} clicked={nextClicked} />
			</Modal.Footer>
		</Modal>
	);
}
