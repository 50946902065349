import {PropsWithChildren} from 'react';
import {CSS} from '@stitches/react';
import {styled} from '../../../../theme';
import {useNavigationBarContext} from '../../context';
import {NavigationBarOrientation} from '../../types';

type NavigationBarContentProps = PropsWithChildren & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
};

export default function NavigationBarContent({children, dataCy, ...props}: NavigationBarContentProps) {
	const {orientation, expanded} = useNavigationBarContext();

	return (
		<Content
			data-cy={dataCy ?? 'ui-kit-navigation-bar-content'}
			orientation={orientation}
			expanded={expanded}
			{...props}
		>
			{children}
		</Content>
	);
}

const Content = styled('section', {
	display: 'flex',

	'& > *': {
		marginBottom: '12px',
	},
	variants: {
		expanded: {true: {}},
		orientation: {
			[NavigationBarOrientation.Vertical]: {
				flexDirection: 'column',
			},
			[NavigationBarOrientation.Horizontal]: {
				flexDirection: 'row',
				alignItems: 'center',
			},
		},
	},
	compoundVariants: [
		{
			orientation: NavigationBarOrientation.Horizontal,
			expanded: false,
			css: {
				visibility: 'hidden',
			},
		},
		{
			orientation: NavigationBarOrientation.Horizontal,
			expanded: true,
			css: {
				visibility: 'visible',
			},
		},
	],
});
