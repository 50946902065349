import {colors} from './colors';

export const themedColors = {
	default: {
		vivid: colors.neutral.neutral99,
		base: colors.neutral.neutral20,
		highContrast: colors.neutral.neutral32,
		mediumContrast: colors.neutral.neutral48,
		lowContrast: colors.neutral.neutral64,
		muted: colors.neutral.neutral80,
		highlight: colors.neutral.neutral80,
		mild: colors.neutral.neutral88,
		border: colors.neutral.neutral92,
		background: colors.neutral.neutral96,
		surface: colors.neutral.neutral99,
		quaternary: colors.indigo.indigo40,

		primary: colors.primary.primary40,
		primaryVivid: colors.primary.primary56,
		primaryMuted: colors.primary.primary88,
		primaryHighlight: colors.primary.primary88,
		primaryBackground: colors.primary.primary96,
		primarySurface: colors.primary.primary99,

		secondary: colors.secondary.secondary56,
		secondaryVivid: colors.secondary.secondary72,
		secondaryMuted: colors.secondary.secondary88,
		secondaryHighlight: colors.secondary.secondary88,
		secondaryBackground: colors.secondary.secondary96,
		secondarySurface: colors.secondary.secondary99,

		positive: colors.green.green48,
		positiveVivid: colors.green.green64,
		positiveMuted: colors.green.green88,
		positiveHighlight: colors.green.green80,
		positiveBackground: colors.green.green96,

		tertiary: colors.orange.orange48,
		tertiaryVivid: colors.orange.orange64,
		tertiaryMuted: colors.orange.orange88,
		tertiaryHighlight: colors.orange.orange88,
		tertiaryBackground: colors.orange.orange96,
		tertiarySurface: colors.orange.orange99,

		negative: colors.red.red48,
		negativeVivid: colors.red.red64,
		negativeMuted: colors.red.red88,
		negativeHighlight: colors.red.red80,
		negativeBackground: colors.red.red96,

		blue: colors.primary.primary64,
		blueBackground: colors.primary.primary88,
		red: colors.red.red64,
		redBackground: colors.red.red88,
		green: colors.green.green64,
		greenBackground: colors.green.green88,
		purple: colors.secondary.secondary64,
		purpleBackground: colors.secondary.secondary88,
		orange: colors.orange.orange64,
		orangeBackground: colors.orange.orange88,
		indigo: colors.indigo.indigo64,
		indigoBackground: colors.indigo.indigo88,
		yellow: colors.yellow.yellow64,
		yellowBackground: colors.yellow.yellow88,
	},
	highContrast: {
		vivid: colors.neutral.neutral99,
		base: colors.neutral.neutral8,
		highContrast: colors.neutral.neutral12,
		mediumContrast: colors.neutral.neutral24,
		lowContrast: colors.neutral.neutral40,
		muted: colors.neutral.neutral56,
		highlight: colors.neutral.neutral20,
		mild: colors.neutral.neutral56,
		border: colors.neutral.neutral80,
		background: colors.neutral.neutral96,
		surface: colors.neutral.neutral99,
		quaternary: colors.indigo.indigo40,

		primary: colors.primary.primary24,
		primaryVivid: colors.primary.primary24,
		primaryMuted: colors.primary.primary88,
		primaryHighlight: colors.primary.primary24,
		primaryBackground: colors.primary.primary96,
		primarySurface: colors.primary.primary99,

		secondary: colors.secondary.secondary40,
		secondaryVivid: colors.secondary.secondary72,
		secondaryMuted: colors.secondary.secondary40,
		secondaryHighlight: colors.secondary.secondary88,
		secondaryBackground: colors.secondary.secondary96,
		secondarySurface: colors.secondary.secondary40,

		positive: colors.green.green32,
		positiveVivid: colors.green.green32,
		positiveMuted: colors.green.green88,
		positiveHighlight: colors.green.green32,
		positiveBackground: colors.green.green96,

		tertiary: colors.orange.orange32,
		tertiaryVivid: colors.orange.orange32,
		tertiaryMuted: colors.orange.orange88,
		tertiaryHighlight: colors.orange.orange32,
		tertiaryBackground: colors.orange.orange96,
		tertiarySurface: colors.orange.orange99,

		negative: colors.red.red32,
		negativeVivid: colors.red.red32,
		negativeMuted: colors.red.red88,
		negativeHighlight: colors.red.red32,
		negativeBackground: colors.red.red96,

		blue: colors.primary.primary48,
		blueBackground: colors.primary.primary88,
		red: colors.red.red48,
		redBackground: colors.red.red88,
		green: colors.green.green48,
		greenBackground: colors.green.green88,
		purple: colors.secondary.secondary48,
		purpleBackground: colors.secondary.secondary88,
		orange: colors.orange.orange48,
		orangeBackground: colors.orange.orange88,
		indigo: colors.indigo.indigo48,
		indigoBackground: colors.indigo.indigo88,
		yellow: colors.yellow.yellow48,
		yellowBackground: colors.yellow.yellow88,
	},
	dark: {
		vivid: colors.neutral.neutral99,
		base: colors.neutral.neutral80,
		highContrast: colors.neutral.neutral72,
		mediumContrast: colors.neutral.neutral48,
		lowContrast: colors.neutral.neutral40,
		muted: colors.neutral.neutral32,
		highlight: colors.neutral.neutral32,
		mild: colors.neutral.neutral24,
		border: colors.neutral.neutral16,
		background: colors.neutral.neutral12,
		surface: colors.neutral.neutral10,
		quaternary: colors.indigo.indigo40,

		primary: colors.primary.primary64,
		primaryVivid: colors.primary.primary56,
		primaryMuted: colors.primary.primary20,
		primaryHighlight: colors.primary.primary20,
		primaryBackground: colors.primary.primary14,
		primarySurface: colors.primary.primary6,

		secondary: colors.secondary.secondary72,
		secondaryVivid: colors.secondary.secondary20,
		secondaryMuted: colors.secondary.secondary20,
		secondaryHighlight: colors.secondary.secondary16,
		secondaryBackground: colors.secondary.secondary12,
		secondarySurface: colors.secondary.secondary80,

		positive: colors.green.green56,
		positiveVivid: colors.green.green64,
		positiveMuted: colors.green.green20,
		positiveHighlight: colors.green.green20,
		positiveBackground: colors.green.green12,

		tertiary: colors.orange.orange72,
		tertiaryVivid: colors.orange.orange56,
		tertiaryMuted: colors.orange.orange24,
		tertiaryHighlight: colors.orange.orange24,
		tertiaryBackground: colors.orange.orange16,
		tertiarySurface: colors.orange.orange12,

		negative: colors.red.red64,
		negativeVivid: colors.red.red64,
		negativeMuted: colors.red.red32,
		negativeHighlight: colors.red.red32,
		negativeBackground: colors.red.red32,

		blue: colors.primary.primary40,
		blueBackground: colors.primary.primary16,
		red: colors.red.red,
		redBackground: colors.red.red16,
		green: colors.green.green,
		greenBackground: colors.green.green16,
		purple: colors.secondary.secondary40,
		purpleBackground: colors.secondary.secondary16,
		orange: colors.orange.orange,
		orangeBackground: colors.orange.orange16,
		indigo: colors.indigo.indigo40,
		indigoBackground: colors.indigo.indigo16,
		yellow: colors.yellow.yellow,
		yellowBackground: colors.yellow.yellow16,
	},
};
