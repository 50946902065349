import {PropsWithChildren} from 'react';
import {CSS} from '@stitches/react';
import {styled} from '../../../../theme';
import {useNavigationBarContext} from '../../context';
import {NavigationBarOrientation} from '../../types';

type NavigationBarDividerProps = PropsWithChildren & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
};

export default function NavigationBarHead({children, dataCy, ...props}: NavigationBarDividerProps) {
	const {expanded, orientation} = useNavigationBarContext();
	return (
		<Container
			data-cy={dataCy ?? 'ui-kit-navigation-bar-head'}
			expanded={expanded}
			orientation={orientation}
			{...props}
		>
			{children}
		</Container>
	);
}

const Container = styled('div', {
	display: 'flex',
	alignItems: 'center',
	paddingTop: '3px',

	'& svg': {
		transition: 'height 0.3s, width 0.3s',
	},
	variants: {
		expanded: {
			true: {
				padding: '20px 12px 0',
			},
		},
		orientation: {
			[NavigationBarOrientation.Vertical]: {
				flexDirection: 'column',
				padding: '24px 8px 0',
			},
			[NavigationBarOrientation.Horizontal]: {
				paddingLeft: '12px',
			},
		},
	},
});
