import {SelectableListGroup} from './components/selectable-list-group';
import {SelectableListGroupDroppable} from './components/selectable-list-group-droppable';
import {SelectableListGroupRoot} from './components/selectable-list-group-root';
import {SelectableListItem} from './components/selectable-list-item';
import {SelectableListItemDraggable} from './components/selectable-list-item-draggable';
import {SelectableListRoot} from './components/selectable-list-root';
import {SelectableListStyledButton} from './components/selectable-list-styled-button';
import {SelectableListTrigger} from './components/selectable-list-trigger';
import {SelectableListTriggerIcon} from './components/selectable-list-trigger-icon';
import {SelectableListGroupNumerableRoot} from './variant/numerable/selectable-list-group-root';
import {SelectableListNumerableItem} from './variant/numerable/selectable-list-item';
import {SelectableListNumerableItemDraggable} from './variant/numerable/selectable-list-item-draggable';

const BaseSelectableList = {
	Root: SelectableListRoot,
	Trigger: SelectableListTrigger,
	TriggerIcon: SelectableListTriggerIcon,
	StyledButton: SelectableListStyledButton,
	Group: SelectableListGroup,
	GroupDroppable: SelectableListGroupDroppable,
};

export const SelectableList = {
	...BaseSelectableList,
	GroupRoot: SelectableListGroupRoot,
	Item: SelectableListItem,
	ItemDraggable: SelectableListItemDraggable,
};

export const SelectableListNumerable = {
	...BaseSelectableList,
	GroupRoot: SelectableListGroupNumerableRoot,
	Item: SelectableListNumerableItem,
	ItemDraggable: SelectableListNumerableItemDraggable,
};

export * from './components/selectable-list-group-root/context';
export {type SelectableListNumerableItemProps} from './variant/numerable/selectable-list-item';
export {type SelectableListNumerableItemDraggableProps} from './variant/numerable/selectable-list-item-draggable';
export * from './components/selectable-list-group-root/context';
