import {styled} from '../../theme';
import {animated} from 'react-spring';

export const Overlay = styled(animated.div, {
	width: '100%',
	height: '100%',
	background: 'linear-gradient(332deg, $neutral94, $neutral98)',
});

export const Root = styled(animated.div, {
	position: 'absolute',
	top: '0',
	left: '0',
	width: '100%',
	height: '100%',
	overflow: 'hidden',
});

export const Content = styled(animated.div, {
	position: 'absolute',
	top: 0,
	right: 0,
	height: '100%',
	borderLeft: '1px solid $mild',
	background: '$background',
	boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(230, 232, 239, 0.48)',
});