import {useAutoControlledState} from '../../../utils';
import {Root, TooltipProps} from '@radix-ui/react-tooltip';
import {TooltipContext, TooltipContextValue} from '../../context';
import {useMemo} from 'react';
import {TooltipProvider} from '../tooltip-provider';

type TooltipRootProps = Omit<TooltipProps, 'delayDuration'> & {
	/**
	 * The duration from when the pointer enters the trigger until the tooltip gets opened. This will
	 * override the prop with the same name passed to Provider.
	 * Depends on <Tooltip.Content /> variant prop. ('default' value set 400ms, 'secondary' - 1000ms)
	 */
	delayDuration?: number;
};

export function TooltipRoot({
	delayDuration: externalDelayDuration,
	open,
	defaultOpen,
	onOpenChange,
	...props
}: TooltipRootProps) {
	const [delayDuration, setDelayDuration] = useAutoControlledState({
		initialState: undefined,
		controlledState: externalDelayDuration,
	});

	const [isShownContent, setIsShownContent] = useAutoControlledState({
		initialState: defaultOpen ?? false,
		controlledState: open,
		onChange: onOpenChange,
	});

	const contextValue = useMemo<TooltipContextValue>(() => {
		return {
			delayDuration,
			setDelayDuration,
			isShownContent,
		};
	}, [delayDuration, setDelayDuration, isShownContent]);

	return (
		<TooltipProvider>
			<TooltipContext.Provider value={contextValue}>
				<Root delayDuration={delayDuration} open={isShownContent} onOpenChange={setIsShownContent} {...props} />
			</TooltipContext.Provider>
		</TooltipProvider>
	);
}
