import {Fragment, Suspense, useCallback, useEffect, useState, useMemo} from 'react';
import {AssignmentCard, SelfAssessmentData} from './components/assignment-card';
import {InfoText} from './components/info-text';
import {LayoutPage, lazyComponent} from '@ac/kit';
import {StudentAssignmentsService} from './services';
import {isIOS, join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useService} from '@esgillc/core/service';
import styles from './styles.module.less';

const SelfAssessment = lazyComponent(() => import('@ac/modules/self-assessment'));

export function StudentAssignments() {
	const service = useService(StudentAssignmentsService);
	const studentAssignmentsData = useBehaviorSubject(service.studentAssignmentsData$);
	const [forceUpdate, setForceUpdate] = useState<{}>();
	const [selfAssessmentData, setSelfAssessmentData] = useState<SelfAssessmentData | null>(null);

	useEffect(() => {
		service.init();
	}, [forceUpdate]);

	const onSelfAssessmentStart = useCallback(({assignmentID, userID}: SelfAssessmentData) => {
		setSelfAssessmentData({assignmentID, userID});
	}, []);

	const onSelfAssessmentClose = useCallback(() => {
		setSelfAssessmentData(null);
	}, []);

	const testSessionEnded = useCallback(() => {
		setForceUpdate({});
	}, []);

	const completeAssignmentsCount = useMemo(
		() => studentAssignmentsData?.assignmentsInfo?.completeAssignmentsCount?.toString(),
		[studentAssignmentsData],
	);

	const schoolYearAssignments = useMemo(() => {
		if (!studentAssignmentsData) {
			return [];
		}
		return studentAssignmentsData?.assignmentsInfo?.schoolYearAssignments || [];
	}, [studentAssignmentsData]);

	if (!studentAssignmentsData) {
		return null;
	}

	return (
		<>
			{!selfAssessmentData && (
				<LayoutPage pageTitle={studentAssignmentsData.studentName} mainClassName={styles.layout}>
					<div className={join(styles.container, isIOS() && styles.mobile)}>
						<div className={styles.assignmentInfo}>
							<InfoText mainText='Completed assignments' additionText={completeAssignmentsCount ?? ''} />
						</div>
						<div className={styles.assignmentList}>
							{schoolYearAssignments.map(({schoolYearName, activeAssignments}) => (
								<div className={styles.schoolYearAssignmentsBlock}>
									<div className={styles.schoolYearName}>{schoolYearName}</div>
									{activeAssignments.map(({name, publishDate, testsProgress, id, userID, userName}) => (
									<AssignmentCard
										key={id}
										publishDate={publishDate}
										assignmentName={name}
										onSelfAssessmentStartClick={onSelfAssessmentStart}
										selfAssessmentProps={{id, testsProgress, userID}}
										selfAssessTestingOpened={Boolean(selfAssessmentData)}
										createdAssignmentUserName={userName}
									/>
								))}
								</div>
							))}
							{schoolYearAssignments.length === 0 && (
								<div className={styles.assignmentEmpty}>You have completed all your assignments.</div>
							)}
						</div>
					</div>
				</LayoutPage>
			)}
			{selfAssessmentData && (
				<Suspense fallback={<Fragment />}>
					<SelfAssessment
						assignmentID={selfAssessmentData.assignmentID}
						closed={onSelfAssessmentClose}
						studentName={studentAssignmentsData.studentName}
						testSessionEnded={testSessionEnded}
						awsBucket={studentAssignmentsData.awsBucket}
						userID={selfAssessmentData.userID}
					/>
				</Suspense>
			)}
		</>
	);
}
