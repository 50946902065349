import {CSS, VariantProps} from '@stitches/react';
import {PropsWithChildren, forwardRef} from 'react';
import {useInfoBlockContextContext} from '../../context';
import {InfoBlock, TitleBlock} from './index.styled';
import {Text} from '../../../../typography';
import {Skeletonable} from '../../../../types';

type InfoBlockItemProps = PropsWithChildren<{
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;

	/** Info block title. If passed, title will be rendered. */
	title?: string;

	/** Color variant for item block.
	 * @default "white"
	 */
	colorVariant?: NonNullable<VariantProps<typeof InfoBlock>['colorVariant']>;
}> &
	Skeletonable;

export const InfoBlockItem = forwardRef<HTMLDivElement, InfoBlockItemProps>(
	({children, css = {}, dataCy = 'ui-kit-info-block-item', title, colorVariant = 'white', skeleton, ...props}, forwardedRef) => {
		const {inBlock} = useInfoBlockContextContext();

		return (
			<InfoBlock
				dataCy={dataCy}
				css={css}
				ref={forwardedRef}
				colorVariant={colorVariant}
				type={inBlock ? 'block' : 'standalone'}
				{...props}
			>
				{title && (
					<TitleBlock dataCy={`${dataCy}-title-block`}>
						<Text size='small' font='mono'>
							{title}
						</Text>
					</TitleBlock>
				)}
				{children}
			</InfoBlock>
		);
	},
);
