import {PropsWithChildren, useCallback} from 'react';
import styles from './layout-page.module.less';
import {NameBar} from '../name-bar';
import {Buttons} from '@esgillc/ui-kit/button';
import {IconLogout} from '../icon';
import {useLocation, useNavigate} from 'react-router-dom';
import {routePaths} from '@ac/router/routes';
import {join} from '@esgillc/ui-kit/utils';
import {useService} from '@esgillc/core/service';
import {LogoutService} from './logout-service';

type LayoutPageProps = PropsWithChildren<{
	pageTitle: string;
	mainClassName?: string | undefined;
}>;

export function LayoutPage({pageTitle, children, mainClassName}: LayoutPageProps) {
	const navigate = useNavigate();
	const {pathname} = useLocation();

	const logoutService = useService(LogoutService);

	const isLoginPage = pathname === routePaths.login;

	const onLogout = useCallback(() => {
		logoutService.logout().subscribe(() => {
			navigate(routePaths.login, {
				replace: true,
			});
		});
	}, [navigate, logoutService]);

	return (
		<div className={styles.page}>
			<header className={join(!isLoginPage && styles.withLogoutButton)}>
				<NameBar title={pageTitle} className={styles.namebar} />
				{!isLoginPage && (
					<Buttons.Icon className={styles.logout} onClick={onLogout}>
						<IconLogout />
						Logout
					</Buttons.Icon>
				)}
			</header>
			<main className={mainClassName}>{children}</main>
		</div>
	);
}
