import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {styled} from '../../../theme';
import {BaseComponentProps, Skeletonable} from '../../../types';
import {css} from '../../../theme/stitches.config';
import {SkeletonStyles} from '../../../skeleton';

export type BaseButtonProps = BaseComponentProps &
	ComponentPropsWithoutRef<'button'> & {
		/** Text style. */
		uppercase?: boolean;
	} & Skeletonable;

export const BaseButton = forwardRef<HTMLButtonElement, BaseButtonProps>(
	({dataCy, skeleton, css = {}, ...props}, forwaredRef) => (
		<Button data-cy={dataCy ?? 'ui-kit-base-button'} css={css} ref={forwaredRef} skeleton={skeleton} {...props} />
	),
);

export const baseButtonStyles = css({
	all: 'unset',
	display: 'inline-flex',
	textDecoration: 'none',
	position: 'relative',
	boxSizing: 'border-box',
	userSelect: 'none',
	transition: 'background-color .3s, color .3s, border-color .3s, box-shadow .3s',
	cursor: 'pointer',
	outline: 'none',
	appearance: 'none',
	padding: 0,
	margin: 0,
	overflow: 'visible',
	border: 'none',
	backgroundColor: 'transparent',
	justifyContent: 'center',

	'&:focus': {
		outline: 'none',
	},

	'&:active': {
		outline: 'none',
	},

	'&:disabled': {
		cursor: 'default',
	},
});

const Button = styled('button', baseButtonStyles, {
	variants: {
		skeleton: {
			true: SkeletonStyles,
		},
		uppercase: {
			true: {
				textTransform: 'uppercase',
			},
		},
	},
});
