import {useEffect} from 'react';
import {useService} from '@esgillc/core/service';
import {SSOService} from '../service';

export function ByClassLink() {
	const service = useService(SSOService);

	useEffect(() => {
		service.handleClasslink();
	}, [service]);

	return null;
}