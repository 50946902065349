import React, {CSSProperties} from 'react';
import styles from './with-four-columns.module.less';
import {TemplateProps} from '../template';
import {BaseOption} from '../../option/option';
import {Question} from '@packages/self-assess';
import {isMultiple} from '../../utils';


class WithFourColumns extends React.Component<TemplateProps> {
	private optionsContainerRef = React.createRef<HTMLDivElement>();

	private selected(id: number): boolean {
		return this.props.selectedOptionID.includes(id);
	}

	private cssStyles(q: Question): CSSProperties {
		const css: CSSProperties = {
			gridTemplateColumns: `repeat(${q.options.length}, 1fr)`,
		};

		return css;
	}

	render() {
		const q = this.props.question;
		return <div className={styles.template}>
			<div ref={this.optionsContainerRef} className={styles.optionsContainer} style={this.cssStyles(q)}>
				{q.options.map((item, index) => {
					return <BaseOption
						bucket={this.props.awsBucket}
						type={'Vertical'}
						id={item.id}
						disabled={this.props.maxOptionsSelected && !this.props.selectedOptionID.includes(item.optionID)}
						className={styles.option}
						multiselect={isMultiple(q)}
						key={item.optionID}
						text={item.text}
						clicked={() => this.props.onSelectOption(item.optionID)}
						selected={this.selected(item.optionID)}
						audioPath={item.audioUrl}
						picUrl={item.picUrl}
						disableAudio={this.props.audioPlaying}
					/>;
				})}
			</div>
		</div>;
	}
}

export default WithFourColumns;
