import {forwardRefWithGenerics} from '../../../../utils';
import {ForwardedRef} from 'react';
import {SelectableListItem, SelectableListItemProps} from '../../components/selectable-list-item';

export type SelectableListNumerableItemProps<T extends number = number> = Omit<SelectableListItemProps, 'value'> & {
	value: T;
};

export const SelectableListNumerableItem = forwardRefWithGenerics(function SelectableListNumerableItem<
	T extends number = number,
>({value, ...props}: SelectableListNumerableItemProps<T>, forwardedRef: ForwardedRef<HTMLLIElement>) {
	return <SelectableListItem value={String(value)} ref={forwardedRef} {...props} />;
});
