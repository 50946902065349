import {useSelectableListContext} from '../../context';
import {List, ListGroupProps} from '../../../list';
import {forwardRef, useRef} from 'react';
import {useComposedRefs} from '../../../../utils';
import {useSpring, config, animated} from 'react-spring';

export type SelectableListGroupProps = ListGroupProps;

export const SelectableListGroup = forwardRef<HTMLUListElement, SelectableListGroupProps>(
	({dataCy = 'ui-kit-selectable-list-group', ...props}, forwaredRef) => {
		const {isExpandableList, isListOpen, isLoaded} = useSelectableListContext();

		const listInnerRef = useRef<HTMLUListElement>(null);

		const listRefs = useComposedRefs(listInnerRef, forwaredRef);

		const getHeightFrom = () => {
			if (isExpandableList) {
				if (!isLoaded) {
					return isListOpen ? undefined : 0;
				}
			}
		};

		const getHeightTo = () => {
			if (isExpandableList) {
				if (!isLoaded) {
					return isListOpen ? undefined : 0;
				}

				if (isLoaded) {
					return isListOpen ? listInnerRef.current?.clientHeight : 0;
				}
			}
		};

		const {height} = useSpring({
			from: {
				height: getHeightFrom(),
			},
			to: {
				height: getHeightTo(),
			},
			config: {
				...config.gentle,
				clamp: true,
			},
		});

		return (
			<animated.div style={{overflow: 'hidden', height}}>
				<List dataCy={dataCy} ref={listRefs} {...props} />
			</animated.div>
		);
	},
);
