import {useCallback, useState} from 'react';
import {StudentAssignmentTestingService} from './services/student-assignment-testing-service';
import {StudentViewModal} from './components/student-view-modal';
import {filter, switchMap, withLatestFrom} from 'rxjs';
import {FinishTestSessionModal} from './components/finish-test-session-modal';
import {useServiceFactory} from '@esgillc/core/service';

type SelfAssessmentProps = {
	closed: () => void;
	studentName: string;
	testSessionEnded: () => void;
	awsBucket: string;
	assignmentID: number;
	userID: number;
};

export const SelfAssessment = ({
	closed,
	testSessionEnded,
	studentName,
	awsBucket,
	assignmentID,
	userID,
}: SelfAssessmentProps) => {
	const [showStudentViewModal, setShowStudentViewModal] = useState(false);
	const [showFinishTestSessionModal, setShowFinishTestSessionModal] = useState(false);

	const studentAssignmentTestingService = useServiceFactory(() => {
		const service = new StudentAssignmentTestingService({awsBucket, assignmentID, userID});

		service.startTestSession$().subscribe(() => setShowStudentViewModal(true));

		service.finishTest$
			.pipe(
				switchMap((_) => service.testFinish$()),
				withLatestFrom(service.finishTest$),
				filter(([_, isLastTest]) => isLastTest),
				switchMap((_) => service.endTestSession$()),
			)
			.subscribe();

		return service;
	});

	const studentViewModalClose = useCallback(() => {
		setShowStudentViewModal(false);
		testSessionEnded();
		closed();
	}, [testSessionEnded, closed]);

	const studentViewModalTestSessionEnd = useCallback(() => {
		setShowStudentViewModal(false);
		setShowFinishTestSessionModal(true);
	}, []);

	const finishTestSession = useCallback(() => {
		setShowFinishTestSessionModal(false);
		testSessionEnded();
		closed();
	}, [testSessionEnded, closed]);

	if (showStudentViewModal) {
		return (
			<StudentViewModal
				dataService={studentAssignmentTestingService}
				closed={studentViewModalClose}
				testSessionEnded={studentViewModalTestSessionEnd}
				studentName={studentName}
			/>
		);
	}

	if (showFinishTestSessionModal) {
		return <FinishTestSessionModal studentName={studentName} closed={finishTestSession} />;
	}

	return null;
};

export default SelfAssessment;
