import {styled} from '../../../../theme';
import {Text} from '../../../../typography';

export const Li = styled('li', {
	borderBottomStyle: 'solid',
	borderBottomColor: '$border',
	borderBottomWidth: 1,
	backgroundColor: '$vivid',
	paddingTop: 6,
	paddingBottom: 6,
	paddingLeft: 12,
	paddingRight: 12,
	color: '$base',
	boxSizing: 'border-box',
	userSelect: 'none',

	variants: {
		setTextCurrentColor: {
			true: {
				[`& ${Text}`]: {
					color: 'currentColor',
				},
			},
		},
	},
});
