import {styled} from '../../../theme';
import {Text} from '../../../typography';
import {BaseButton} from '../base-button';

const iconSize = 16;
const desiredIconSize = 24;

export const ButtonStyled = styled(BaseButton, {
	borderWidth: '1px',
	borderStyle: 'solid',
	display: 'flex',
	alignItems: 'center',
	columnGap: 6,

	[`& ${Text}`]: {
		color: 'currentColor',
	},

	'& > svg, & > svg *': {
		fill: 'currentColor',

		'& > *': {
			fill: 'currentColor',
		},
	},

	'& > svg': {
		width: iconSize,
		height: iconSize,
		scale: desiredIconSize / iconSize,
	},

	'&[data-state-error]': {
		backgroundColor: '$negativeBackground',
		borderColor: '$negativeMuted',
		color: '$negative',
		boxShadow: 'none',
	},

	'&:hover': {
		backgroundColor: '$secondaryBackground',
		borderColor: '$secondaryMuted',
		color: '$secondary',
		boxShadow: '0px 4px 12px 0px rgba(230, 219, 238, 0.40), 0px 4px 12px 0px rgba(0, 0, 0, 0.06)',
	},

	'&:active': {
		backgroundColor: '$surface',
		borderColor: '$primaryMuted',
		color: '$primary',
		boxShadow: `0px 2px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(230, 232, 239, 0.48)`,
	},

	'&:disabled': {
		backgroundColor: '$background',
		borderColor: '$border',
		color: '$lowContrast',
		boxShadow: 'none',
	},

	variants: {
		/** Color variants. Default value is "primary". */
		color: {
			primary: {
				minHeight: 36,
				backgroundColor: '$primary',
				borderColor: '$primary',
				padding: '7px 19px',
				borderRadius: '8px',
				color: '$surface',
			},

			secondary: {
				minWidth: 120,
				borderRadius: 6,
				borderColor: '$border',
				backgroundColor: '$surface',
				boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(230, 232, 239, 0.24)',
				paddingTop: 7,
				paddingBottom: 7,
				paddingLeft: 11,
				paddingRight: 11,
				color: '$base',

				'& > svg:only-child': {
					minWidth: 'unset',
				},
			},

			tertiary: {
				minWidth: 120,
				borderRadius: 6,
				borderColor: 'transparent',
				backgroundColor: 'transparent',
				paddingTop: 7,
				paddingBottom: 7,
				paddingLeft: 11,
				paddingRight: 11,
				color: '$base',

				'&:hover': {
					borderColor: '$secondaryBackground',
					boxShadow: 'none',
					color: '$secondary',
				},

				'&:hover:active': {
					borderColor: '$primaryMuted',
					boxShadow: `0px 2px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(230, 232, 239, 0.48)`,
				},

				'&:disabled, &:disabled:active': {
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					boxShadow: 'none',
				},
			},

			warn: {
				minWidth: 120,
				borderRadius: 6,
				borderColor: '$negativeMuted',
				backgroundColor: '$negativeBackground',
				color: '$negative',
				paddingTop: 7,
				paddingBottom: 7,
				paddingLeft: 11,
				paddingRight: 11,
			},
		},
	},
});
